import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner/InnerBanner";

export default function PrivacyPolicy() {
  return (
    <>
      <InnerBanner
        InnerBannerTitle="Privacy Policy"
        PreviousPageName="Home"
        PreviousPageLink="/"
        CurrentPage="Privacy Policy"
      />
      <section className="terma-of-services-page inner-page">
        <Container>
          <Row>
            <Col md={12}>
              <p>
                We, Club Debate Algorithm (“we”, “us”, “Club Debate”) value the
                trust placed in us by You and therefore, we follow the highest
                standards of privacy guidelines to protect the information
                shared by You with us.
              </p>
              <p>
                This Privacy Policy (“Privacy Policy”) governs the use of Your
                Personal Information shared (as defined below) with or collected
                by Club Debate from the users of the “Club Debate Algorithm”
                platform, including the Club Debate website, and mobile
                applications (“Club Debate Algorithm”).
              </p>
              <p>
                Your viewing or browsing of our content or Your use of our
                services offered on Club Debate is constructed to mean that You
                consent to our use, collection, storage, processing, alteration,
                retrieval, indexing, erasure, making available of, and/or
                transfer (“Processing”) of your Personal Information in
                accordance with this Privacy Policy, and you agree to be bound
                by the terms of this Privacy Policy.
              </p>
              <p>
                If You do not agree with the terms contained herein, please do
                not view, browse or download Club Debate/ Club Debate Algorithm.
                By mere browsing or use of Club Debate platform or its
                application, You consent to the terms of this Privacy Policy and
                agree to the Processing of Your Personal Information in the
                manner as provided under this Privacy Policy.
              </p>
              <p>
                This Privacy Policy is a part of, and should be read together
                with the Terms of Use . All capitalized terms that have not been
                specifically defined herein shall have the same meaning as
                provided under the Terms of Use.
              </p>
              <p>
                <ul>
                  <li>
                    <div>Data provided by user</div>
                    <p>
                      We collect information with respect to Your name, mobile
                      number, email, address, IP Address etc. and as further
                      detailed in this policy (“Personal Information”).
                    </p>
                    <p>
                      We collect Personal Information about You when You use or
                      browse Club Debate, at the time of Your registration with
                      Club Debate. Data is also collected when You share
                      information with us through email, by posting on Club
                      Debate platforms or by any other means like customer care
                      centre, etc made available to You by us.
                    </p>
                    <p>
                      Based on Your purchase of a subscription package, etc ,
                      our payment gateway partners or pre-paid instrument
                      service providers, as applicable, collect information with
                      respect to Your credit/debit card details, net banking, or
                      other bank account details. For instance, Club Debate/
                      Club Debate Algorithm will be using third party payment
                      gateway providers to process and facilitate the payment of
                      Your Subscription Fee or any transaction to Club Debate/
                      Club Debate Algorithm which will enable your access to the
                      subscription, etc. This information is stored by our
                      payment gateway partners or pre-paid instrument service
                      providers, as applicable, and not by Club Debate/ Club
                      Debate Algorithm.
                    </p>
                    <p>
                      Please note that Club Debate may enter into agreements or
                      using third party payment gateway aggregators and
                      financial institutions for collecting , refund and
                      remittance and to facilitate payments. While availing any
                      of the payment method/s available on the platform (Club
                      Debate/ Club Debate Algorithm) the Club Debate Algorithm /
                      Club Debate Algorithm their affiliates and the platform /
                      Club Debate Algorithm will not responsible or assume any
                      liability , whatsoever in respect of any loss or damage
                      arising directly or indirectly to the user/s . Such
                      payment gateway partners or pre-paid instrument service
                      providers, as applicable, are not controlled by us. When
                      you visit such payment gateways or pre-paid instrument
                      service providers, as applicable, you do so at your own
                      risk. These payment gateways or pre-paid instrument
                      service providers, as applicable, may have their own
                      privacy policies in place, which we recommend You review.
                      We also encourage you to familiarize yourself with the
                      privacy statements provided by such payment gateways or
                      pre-paid instrument service providers, as applicable,
                      prior to providing them with information about you or
                      entering into any transactions with them. We do not assume
                      any responsibility or liability for such partners, the
                      content of such payment gateways or pre-paid instrument
                      service providers, as applicable, and their privacy
                      practices, nor do we endorse them.
                    </p>
                    <p>
                      If you choose to log-in, access or otherwise connect to
                      Club Debate, through a third-party service (such as
                      Facebook, Twitter, etc), we may, for example, collect your
                      user ID and user name associated with that service, as
                      well as any information you make public using that
                      service. We may also collect information you have
                      authorized the service to share with us (such as your user
                      ID, billing information, public profile information, email
                      address, birthday, and other account and profile data).
                    </p>
                    <p>
                      We collect information in order to provide You with a
                      safe, efficient, smooth and customized experience. This
                      allows us to provide services and features that most
                      likely meet Your needs, and to customize Club Debate to
                      make Your experience safer and easier.
                    </p>
                    <p>
                      Except as provided herein, we do not solicit any sensitive
                      Personal Information about You. However, if You share such
                      information with us voluntarily, we will not be liable for
                      any actions, claims, costs, expenses or other liabilities
                      that may arise as a consequence of such any unauthorized
                      use or misuse of such information.
                    </p>
                  </li>
                  <li>
                    <div> Inferred Information through usage and log data</div>
                    <p>
                      We may also use Your information to: (i) track user
                      behaviour; (ii) evaluate Your interest for various genres
                      of content, services, offers; and (iii) perform analytics
                      and conduct customer research, to determine Your interest,
                      for identifying Content that generates sales and to
                      analyse traffic patterns, demographics, devices,
                      interests, and behaviour to better understand, protect and
                      serve our users. This information is compiled and analysed
                      on an aggregated basis. This information may include the
                      platform that You just visited (whether this platform is
                      on Club Debate or not), which platform You next visit, and
                      Your computer browser information.
                    </p>
                    <p>
                      If You choose to post messages on social media accounts,
                      message boards, chat rooms or other message areas or leave
                      feedback, we will collect that information You provide to
                      Us. We retain this information as necessary to resolve
                      disputes, provide customer support and troubleshoot
                      problems as permitted by law.
                    </p>
                    <p>
                      If You send us personal correspondence, such as emails or
                      letters, or if other users or third parties send us
                      correspondence about Your activities or postings on Club
                      Debate, we may collect and store such information. We
                      identify and use Your IP address to help diagnose any
                      problems with our server, and to administer Club Debate.
                      Your IP address is also used to help identify You and to
                      gather broad demographic information to customize Your
                      experience on Club Debate.
                    </p>
                    <p>
                      We will occasionally ask You to complete optional online
                      surveys. These surveys may ask You for contact information
                      and demographic information (like zip code, age, etc). We
                      use this data to tailor Your experience at Club Debate,
                      providing You with content that We think You might be
                      interested in and to display content according to Your
                      preferences.
                    </p>
                  </li>
                  <li>
                    <div>Cookie</div>
                    <p>
                      A "cookie" is a small piece of information stored by a web
                      server on a web browser so it can be later read back from
                      that browser. Cookies are useful for enabling the browser
                      to remember information specific to a given user. We place
                      both permanent and temporary cookies in Your computer's
                      hard drive. The cookies do not contain any of Your
                      Personal Information.{" "}
                    </p>
                    <p>
                      We may combine Your Personal Information, other
                      information and information collected from tracking
                      technologies and aggregate it with information collected
                      from other users using our service to attempt to provide
                      You with the Service and a better experience on Club
                      Debate.
                    </p>
                  </li>
                  <li>
                    <p>
                      You expressly understand, agree and acknowledge that our
                      Processing of your Personal Information is for a lawful
                      purpose connected with a function or activity of Club
                      Debate platform. You hereby consent to the Processing of
                      the Personal Information by Club Debate or Club Debate
                      Algorithm for the purposes set out herein. You further
                      understand, agree and acknowledge that Your Personal
                      Information, including that which is classified as
                      Sensitive Personal Data or Information as per applicable
                      laws, is considered necessary for Club Debate to provide
                      the Service to You and for Your usage of the platform and
                      other services provided by Club Debate and You
                      affirmatively give consent to Club Debate for Processing
                      Your Personal Information.
                    </p>
                    <p>
                      Further, you will have the option to not provide your
                      consent, or withdraw any consent given earlier, provided
                      that the decision to not provide consent / withdrawal of
                      the consent is intimated to us in writing at ( email ID )
                      . If you do not provide us Personal Information or
                      withdraw the consent to provide us with any of your
                      Personal Information at any point in time, we shall have
                      the option not to provide the benefits for the purpose of
                      which the said Personal Information was sought, this and
                      may lead to cancellation of your registration with the
                      Club Debate and/or your access to certain features of Club
                      Debate can be restricted and may also lead to complete
                      cessation of Your access to Club Debate.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      We may disclose or transfer Your Personal Information to
                      affiliates and group companies. We may also disclose or
                      transfer Your Personal Information with third parties
                      inside and outside Canada strictly on confidential terms.
                      We may disclose or transfer such information to third
                      parties in order to provide You access to Club Debate and
                      facilitate Your participation in any contest, game or
                      activity conducted by Club Debate/Club Debate Algorithm,
                      to comply with Our legal obligations, to enforce Our Terms
                      of Use, to facilitate Our marketing and advertising
                      activities, or to prevent, detect, mitigate, and
                      investigate fraudulent or illegal activities related to
                      Club Debate.
                    </p>
                    <p>
                      You hereby provide Your express consent to us for sharing
                      Your Personal Information with third parties and our
                      affiliates for marketing and advertising purposes.
                    </p>
                  </li>
                  <li>
                    <p>
                      We shall endeavour that the third parties and agents
                      engaged by us to whom we may provide access to Your
                      Personal Information for the purposes set out herein are
                      under a contractual obligation to protect Your Personal
                      Information in accordance with the same standard that Club
                      Debate has agreed to be subject to in this Privacy Policy.
                    </p>
                    <p>
                      Club Debate/ Club Debate Algorithm may disclose Your
                      Personal Information to such extent as Club Debate may
                      deem necessary for You to enter into commercial
                      transactions with the website and/or application of Club
                      Debate. Recipients of your Personal Information may be
                      located outside India. This may include countries where
                      applicable data protection laws provide a lesser degree of
                      protection than India. However, You expressly consent to
                      usage of such Personal Information.
                    </p>
                    <p>
                      In the event of any requirement by court order, government
                      or quasi-government agency to disclose Your Personal
                      Information, we will disclose information as may be
                      legally required. We may also disclose Your Personal
                      Information if we, in the good faith believe that such
                      disclosure is reasonably necessary to respond to
                      subpoenas, court orders, or other legal process.
                    </p>
                    <p>
                      In the event Club Debate is merged with or acquired by
                      another company or in case of re-organization or
                      re-structuring of business, we and our affiliates will
                      share Your Personal Information, wholly or in part, with
                      another business entity.
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Club Debate may provide links to various third-party
                      websites that may collect Your Personal Information. Use
                      of Your information collected by such third party platform
                      will be governed by the privacy policy in such third party
                      platform. Club Debate / Club Debate Algorithm has no
                      control on the operation of such third party websites and
                      You may share information with such third party at Your
                      sole risk. Club Debate/ Club Debate Algorithm shall not be
                      liable to You for any misappropriation of Your Personal
                      Information by such third party.
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Promotional and Marketing Activities We will send
                      communications through various modes like SMS, message,
                      email, etc to You at regular intervals to notify You of,
                      among other things, various offers, new content, etc.,
                      that may be available or new services that You may be
                      interest to subscribe to etc. By accessing Club Debate,
                      You expressly consent to receiving such communications
                      from us at regular intervals. However, if You do not wish
                      to receive any communication from us, You may request to
                      unsubscribe from such communication by sending an email to
                      ( email ID ) with the subject as “Unsubscribe”.
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Data Retention We retain Personal Information only for as
                      long as We believe it to be necessary for purposes set out
                      herein for which the Personal Information was collected,
                      subject to any legal requirements for the data to be
                      retained for longer periods of time.
                    </p>
                    <p>
                      Deletion: You can request the deletion of your account and
                      personal information by contacting us by writing email to
                      Club Debatealgorithm@gmail.com.
                    </p>
                  </li>
                  <li>
                    <p>
                      Security and Compliance with Laws We strive to protect
                      Your Personal Information against unauthorized disclosure,
                      misuse, unlawful use, modification, loss or destruction.
                      We take all reasonable measures and precautions, as per
                      standard industry practises, to keep Your Personal
                      Information confidential. We ensure compliance with all
                      applicable laws.
                    </p>
                    <p>
                      We use reasonable administrative, logical, physical and
                      managerial measures to safeguard your personal information
                      against loss, theft and unauthorized access, use and
                      modification as per law. Unfortunately, no measures can be
                      guaranteed to provide 100% security. Accordingly, we
                      cannot guarantee the security of your information. By
                      continuing to access the Club Debate platform you are
                      aware of such risk.
                    </p>
                  </li>
                  <li>
                    <p>
                      Amendments This Privacy Policy is subject to change from
                      time to time without any written notice to You. We reserve
                      the right, at our sole discretion, to modify the terms of
                      this Privacy Policy from time to time in order to ensure
                      compliance with applicable laws (“Updated Terms”). The
                      Updated Terms shall be effective immediately and shall
                      supersede these the terms of this Privacy Policy. Upon
                      updating the Policy, we may revise the "Updated" date at
                      the bottom of this Privacy Policy. We will not be under an
                      obligation to notify you of any changes to this Privacy
                      Policy. You shall be solely responsible for reviewing the
                      Privacy Policy from time to time for any modifications. By
                      continuing to use Club Debate after the Updated Terms have
                      been published, You affirm Your agreement to the Updated
                      Terms.
                    </p>
                  </li>
                  <li>
                    <p>
                      Contact Us If You require any information or clarification
                      regarding the use of Your Personal Information or this
                      Privacy Policy, You can contact us at (Club
                      Debatealgorithm@gmail.com)
                    </p>
                    <p>
                      Club Debate Algorithm is a company based in Canada. By
                      browsing/using Club Debate You hereby acknowledge that
                      Club Debate is not responsible or liable in any manner to
                      comply with any local laws of Your territory except India
                      / Canada / USA with respect to Club Debate.
                    </p>
                  </li>
                  <li>
                    <p>Delete Your Personal Data</p>
                    <p>
                      You have the right to delete or request that We assist in
                      deleting the Personal Data that We have collected about
                      You. Our Service may give You the ability to delete
                      certain information about You from within the Service. You
                      may update, amend, or delete Your information at any time
                      by signing in to Your Account, if you have one, and
                      visiting the account settings section that allows you to
                      manage Your personal information. You may also contact Us
                      to request access to, correct, or delete any personal
                      information that You have provided to Us. Please note,
                      however, that We may need to retain certain information
                      when we have a legal obligation or lawful basis to do so.
                    </p>
                  </li>
                </ul>
              </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
